@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');


* {
    margin: 0;
    padding: 0;
}

body {

    background-color: #ffff; 
    color: #222;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.wrapper {
    min-height: 100%;
    overflow: hidden;
}
.container {
    margin: 0px auto;
    max-width: 1920px !important;
    padding: 0 !important;
}


header .top-header{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; 

    position: relative;
}

header .logo {
    width: auto;
    height: 100px;
    margin-left: 20px;
    align-items: center;
    cursor: pointer;
}
header .logo a {
    display: flex;
    align-items: center;
    height: 100px;
}

header .logo img {
    min-width: 100%;
    height: 50px;

}

header .menu {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

header .phone {
    display: flex;
    flex-direction: column;
    align-items: center;
}

header .phone-number {
    font-size: 20px;
    font-weight: 300;
}
header .phone-mail {
    font-size: 20px;
    font-weight: 300;
}

header ul.nav {
    list-style: none;
    margin-right: 30px;
}
header ul.nav li a {
    text-decoration: none;
    color: black;
}

header ul.nav li {
    display: inline;
    margin-left: 25px;
    cursor: pointer;
    transition: opacity 300ms ease;
    font-size: 20px;
    font-weight: 600;
}

header ul.nav li:hover {
    opacity: 50%;
}

header .menu-button {
    display: none;

}

header .menu.active {
    transform: translateY(145%);
}

@media  screen and (max-width: 990px) {
    header ul.nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    header ul.nav li {
        margin-top: 10px;
    }
    header .phone {
        margin-top: 20px;
    }

    header .menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        transition: transform 500ms ease;
        top: -250px;
        width: 100%;
        background-color: rgb(255, 255, 255);
        z-index: 500;
        
    }
    header .menu-button {
        display: block;
        float: right;
        width: 32px;
        height: 32px;
        margin-right: 20px;
        color: rgb(46, 59, 111)
    }

}

.presentation {
    background: url('./img/slider3_1.png');
    width: 100%;
    height: 600px;
    padding: 20px;
    background-size: cover;
    background-position: center center;
    background-blend-mode: multiply;
    /* background-color: #bcbcbb; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.presentation h2 {
    font-weight: 600;
    font-size: 48px;
    color: #ffffff;
    margin: 0px;

}
.presentation p {
    font-weight: 300;
    font-size: 36px;
    color: #ffffff;
    margin-top: 10px;
    
}
@media screen and (min-width: 1400px) {
    .presentation {
        height: 700px;
    }
    .presentation h2 {
        font-weight: 600;
        font-size: 52px;
    }
    .presentation p {
        font-weight: 300;
        font-size: 42px;
    }
}

@media screen and (min-width: 1918px) {
    .presentation {
        height: 1000px;
    }
    .presentation h2 {
        font-weight: 600;
        font-size: 60px;
    }
    .presentation p {
        font-weight: 300;
        font-size: 52px;
    }
}


@media screen and (max-width: 1200px) {
    .presentation {
        height: 600px;
    }
}

@media screen and (max-width: 992px) {
    .presentation {
        height: 500px;
    }
    .presentation h2 {
        font-weight: 600;
        font-size: 40px;
    }
    .presentation p {
        font-weight: 300;
        font-size: 28px;
    }
}

@media screen and (max-width: 768px) {
    .presentation {   
        height: 400px;
    }
    .presentation h2 {
        font-weight: 600;
        font-size: 32px;
        
    }
    .presentation p {
        font-weight: 300;
        font-size: 20px;
    }
}

@media screen and (max-width: 576px) {
    .presentation {
        height: 400px;
        background: url('./img/slider3_2.png');
        background-size: cover;
        background-position: center center;
    }
    .presentation h2 {
        font-weight: 600;
        font-size: 24px;
    }
    .presentation p {
        font-weight: 300;
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {
    .presentation h2 {
        font-weight: 600;
        font-size: 20px;
    }
    .presentation p {
        font-weight: 300;
        font-size: 12px;
    }
}



.carousel {
    width: 90%;
    margin: 0 auto;
    max-height: 900px;
    height: auto;
    overflow: hidden;
}
.carousel-item {
    display: flex;
}
.carousel img {
    width: 100%;
    height: auto;
    max-height: 900px;
    bottom: 20px !important;
}
.carousel-caption {
    top: 1.5em;
    font-size: 36px;
}



.description {
    background-color: rgb(245, 245, 245);
    padding: 50px 20% 50px 20%;
    height: auto;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; */
    text-align: center;
}

.description h2 {
    font-weight: 300;
    font-size: 46px;
}

.description a {
    margin-top: 20px;
    font-weight: 300;
    font-size: 24px;
}

@media screen and (max-width: 992px) {
    .description {
        padding: 50px 10% 50px 10%;
    } 
    .description h2 {
        font-weight: 300;
        font-size: 40px;
    }
    
    .description a {
        margin-top: 20px;
        font-weight: 300;
        font-size: 20px;
    }
}
@media screen and (max-width: 576px) {
    .description {
        padding: 50px 5% 50px 5%;
    } 
    .description h2 {
        font-weight: 300;
        font-size: 36px;
    }
    
    .description a {
        margin-top: 20px;
        font-weight: 300;
        font-size: 18px;
    }

}


.about-us {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgb(245, 245, 245);
}

.about-us-photo {
    width: 50%;
    height: 600px;
    background: url('./img/photo.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.about-us-text{
    width: 50%;
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.about-us-text h2 {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 30px;
}

.about-us-text p:not(:first-child){
    margin-top: 5px;
}

.about-us-text p {
    font-weight: 300;
    font-size: 18px;
}


@media screen and (max-width: 992px) {
    .about-us-text h2 {
        font-weight: 600;
        font-size: 30px;
    }
    
    .about-us-text p {
        font-weight: 300;
        font-size: 15px;
    }
}
@media screen and (max-width: 768px){
    .about-us {
        flex-direction: column;
        align-items: center;
    }
    .about-us-photo {
        width: 100%;
        height: 460px;
    }
    .about-us-text {
        width: 90%;
        padding: 10px 10px 0px 10px;
    }
    .about-us-text h2 {
        font-weight: 600;
        font-size: 36px;
    }
    
    .about-us-text p {
        font-weight: 300;
        font-size: 18px;
    }
}

.product {
    display: flex;
    justify-content: space-between;
    background-color: rgb(245, 245, 245);
}


.product-text {
    width: 50%;
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.product-photo {
    width: 50%;
    height: 600px;
    background: url('./img/product.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

.product-text h2 {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 30px;
}
.product-text p {
    font-weight: 300;
    font-size: 18px;
    line-height: 35px;
}

@media screen and (max-width: 992px) {
    .product-text h2 {
        font-size: 30px;
    }
    .product-text p {
        font-size: 15px;
        line-height: 25px;
    }
}

@media screen and (max-width: 768px){
    .product {
        flex-direction: column-reverse;
        align-items: center;
    }
    .product-photo {
        width: 100%;
    }
    .product-text {
        width: 90%;
        padding: 10px 10px 0px 10px;
    }
    .product-text h2 {
        font-size: 36px;
    }
    .product-text p {
        font-size: 18px;
        line-height: 30px;
    }
}

.features-title {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.features-title h2 {
    font-weight: 600;
    font-size: 42px;
}

.features-title p {
    font-weight: 300;
    font-size: 24px;
    margin: 20px auto;
}


.features-sharp {
    
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
}

.features-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.features-item-left {
    flex-direction: row-reverse;
}
.features-item-txt {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    padding: 10px;
    padding-left: 2%;
}

.features-item-ico img {
    width: 70px;
}

.features-item-title {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.features-item-title h2 {
    font-weight: 300;
    font-size: 30px;
}
.features-item-title li {
    font-weight: 300;
    font-size: 20px;
}
.features-item-title ul {
    padding-left: 25px;
}
.features-item-ico {
    margin-top: 10px;
}

.features-img {
    width: 50%;
    
}
.features-img img {
    width: 100%;
    max-height: 400px;

}

@media screen and (min-width: 1400px) {
    .features-img img {
        width: 100%;
        max-height: 1000px;
    }
    .features-item-txt {
        padding-left: 3%;
    }
    .features-item-title h2 {
        font-weight: 300;
        font-size: 36px;
    }
    .features-item-title li {
        font-weight: 300;
        font-size: 24px;
    }
    .features-item-ico img {
        width: 75px;
    }
    
}

@media screen and (max-width: 1200px) {
    .features-item-title h2 {
        font-weight: 300;
        font-size: 30px;
    }
    .features-item-title li {
        font-weight: 300;
        font-size: 20px;
    }
}

@media screen and (max-width: 992px) {
    .features-item-title h2 {
        font-weight: 300;
        font-size: 24px;
    }
    .features-item-title li {
        font-weight: 300;
        font-size: 16px;
    }
    .features-item-ico img{
        width: 60px;
    }
}

@media screen and (max-width: 768px) {
    .features-item {
        flex-direction: column;
        align-items: center;
    } 
    .features-item:not(:first-child){
        margin-top: 50px !important;
    }

    .features-img {
        width: 100%;
        height: auto;
    }
    .features-item-txt {
        margin-left: 5%;
        width: 100%;        
    }
    .features-item-ico img {
        width: 50px;
    }
    
}

@media screen and (max-width: 576px) {

    .features-item-title h2 {
        font-weight: 300;
        font-size: 20px;
    }
    .features-item-title li {
        font-weight: 300;
        font-size: 14px;
    }
    
}


.catalogue-title {
    font-size: 48px;
    font-weight: 600;
    display: block;
    text-align: center;
    margin: 30px auto 15px auto;
}
.catalogue-text {
    font-size: 20px;
    font-weight: 300;
    display: block;
    text-align: center;
    margin: 0 100px 50px 100px;
}
@media screen and (max-width: 768px) {

    .catalogue-text {
        font-size: 18px;
        margin: 30px 50px;
    }
}

@media screen and (max-width: 576px) {

    .catalogue-text {
        font-size: 16px;
        margin: 30px 50px;
    }
}

.categories {
    background-color: rgb(245, 245, 245);
    padding-top: 50px;
}

.categories h2 {
    font-size: 48px;
    font-weight: 600;
    display: block;
    text-align: center;
}
.categories a{
    text-decoration: none;
    color: black;
}

.categories-sharp {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
}

.categories-item {
    width: 300px;
    height: 300px;
    padding: 10px;
    margin-bottom: 50px; 
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.12);
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.12);
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.12);

    cursor: pointer;
    transition: transform 350ms ease;

}
.categories-item:hover {
    transform: scale(1.05);
}

.categories-item-img {
    width: 100%;
    height: 225px;
    background-color: #ffff;
    display: table;
    text-align: center;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
}

.categories-item-img-block {
    width: 100%;
    height: 250;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    position: relative;
}
.categories-item-img-block img {
    max-height: 225px;
    max-width: 225px;
    vertical-align: middle;
    text-align: center;
    transition: transform 350ms ease;
}

.categories-item-caption h2 {
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    text-align: center;
}

.items {
    margin-left: 20px;
    margin-right: 20px;
    
}

.items-sharp{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.item {
    width: 250px;
    height: 475px;
    margin-bottom: 50px; 
    margin-left: 5px;
    margin-right: 15px;
    position: relative;
    background-color: #ffffff;
    
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.12);
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.12);
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.12);

    transition: transform 350ms ease;
}

.item:hover {
    transform: scale(1.05);
}

.item-block-img {
    width: 250px;
    height: 250px;
    background-color: #ffff;
    display: table;
    text-align: center;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    border-bottom: 1px solid #e6e6e6;
}

.item-img {
    width: 100%;
    height: 250px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.item img {
    max-height: 250px;
    max-width: 250px;
    vertical-align: middle;
    text-align: center;
    
}

.item h2, .item p, .item b {
    color: black;
}


.item h2 {
    font-size: 16px;
    font-weight: 600;
    height: 50px !important;
    text-align: left;
    margin: 5px 10px 5px 10px;
}
.item p {
    color: black;
    font-size: 12px;
    height: 140px;
    margin: 5px 10px 5px 10px;
    overflow: hidden;
}

.item-price {
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: row-reverse;
    
}
.item-price-b {
    float: right;
    padding: 5px 15px;
    color: #ffff;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    background-color: rgba(25, 49, 73, 0.927);
    border-radius: 15px;
    
}

.add-to-cart {
    float: right;
    line-height: 35px;
    vertical-align: middle;
    background: rgb(18,36,54);
    background: linear-gradient(-90deg, rgba(18,36,54,1) 3%, rgba(44,91,138,1) 100%);
    color: #ffff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    width: 100px;
    border-radius: 10px;
    position: absolute;
    bottom: 26px;
    right: 15px;
    cursor: pointer;

    transition: scale 300ms ease;
}

.add-to-cart:hover {
    scale: 1.1;
}

@media screen and (max-width: 770px) {
    .items-sharp {
        justify-content: center;
    }
    
}

.project {
    padding-top: 50px;
    margin-bottom: 50px;
}

.project h2 {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    

}

.project-sharp {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-item-1 {
    background: url('./img/project/1.jpg');
}
.project-item-2 {
    background: url('./img/project/2.jpg');
}
.project-item-3 {
    background: url('./img/project/3.jpg');
}
.project-item-4 {
    background: url('./img/project/4.jpg');
}
.project-item-5 {
    background: url('./img/project/5.jpg');
}
.project-item-6 {
    background: url('./img/project/6.jpg');
}
.project-item-1,
.project-item-2,
.project-item-3,
.project-item-4,
.project-item-5,
.project-item-6 {
    flex: 1 0 25%;
    background-size: cover;
    background-position: center;
    min-width: 360px;
    height: 360px;
    margin: 15px 10px;
}


.form {
    margin: 0px 0 50px 0;
    
}



.form h2{
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    padding: 50px 0 30px 0;
}

.form-blocks{
    display: flex;
    justify-content: center;
}

.form-block {
    margin-top: 25px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    width: 700px
    
}

.form-block-input {
    margin-top: 10px;
    height: 30px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    background: none;
    color: #000000;
    font-size: 16px;
    padding: 0.25rem;
}

.form-block-file {
    margin-top: 10px;
    font-size: 16px;
    color: #ffffff;
}

.form-file {
    margin-top: 20px;
    width: 90%;
    
}

.form-file p {
    font-size: 16px;
    display: inline;
}
.form-file input {
    display: inline;
    margin-left: 20px;
}

.form-block-checkbox input {
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
}
.form-block-checkbox input:checked {
    background-color: rgb(103, 161, 210);
    border-radius: 50%;
}

.form-button {
    margin-top: 20px;
    width: 150px;
    height: 50px;
    border: none;
    border-radius: 15px;
    background-color: rgb(18,36,54);
    color: #ffffff;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    cursor: pointer;
    transition: transform 500ms ease;
}
.form-button:hover {
    transform: scale(1.1)
}

@media screen and (min-width: 1400px) {
    .form-block-input{
        
    }
}

@media screen and (max-width: 1200px) {
    
    
}

@media screen and (max-width: 992px) {
    
    
}

@media screen and (max-width: 768px) {
    .form-block {
        width: 90%;
        margin-left: 0;
    }
    
}




footer {
    text-align: center;
    width: 60%;
    margin: 50px auto 0px auto;
}
footer h2 {
    font-weight: 600;
    font-size: 48px;
    display: block;
}
footer p {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 24px;
    line-height: 42px
}
.footer-contact {
    display: block;
    color: #FF8562;
    margin: 20px 10px;
    font-weight: 300;
    font-size: 32px;
}
.footer-ico {
    display: block;
    
}

.footer-ico a svg {
    width: 52px;
    height: 100%;
    margin: 20px 10px;
    color: red;
    transition: transform 350ms ease;
}
.footer-ico a svg:hover{
    transform: scale(1.1);
}

@media screen and (max-width: 1200px){
    footer {
        width: 80%;
    }
}

@media screen and (max-width: 992px){
    footer {
        width: 85%;
    }
}

@media screen and (max-width: 768px){
    footer {
        width: 90%;
    }
}